import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// For api
import React from "react";
// end of for API
//import { format, getTime } from "date-fns";
import { format } from "date-fns";

const useStyles = makeStyles({
});

function DetailsField(props) {
  const {values,component,variant,className,color} = props;
  const newVar = (typeof variant !== 'undefined' && variant) ? variant : component;

  if (typeof values !== 'undefined' && values) {
//    console.log("values", values);
    let details = values.map(function(value){ return value });
    return(
      <Typography component={component} color={color} variant={newVar} className={className} >
        {details}
      </Typography>);

  } else return;

}


export default function Details(props) {
  const {book} = props;
  const classes = useStyles();
//  const actionBase = '/books/list?book='+book.id;

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <div>
            <DetailsField component={'h3'} values={[book.title]} color="primary"/>
            <DetailsField component={'h4'} values={[book.author_sort]} color="secondary"/>
            <DetailsField component={'h6'}
              values={["Book ",book.series_index," of ",book.series]} />
            <DetailsField component={'p'} variant={'body1'} values={["Published ",format(new Date(book.published), "dd MMMM, yyyy")]} />
          </div>
        {/*
        author_sort: "Fforde, Jasper"
        isbn: "9780670032891"
        last_modified: "2018-11-03 20:40:11.152766+00:00"
        path: "Jasper Fforde/The Well of Lost Plots (15)"
        published: "2004-02-19 00:00:00"
        publisher: 51
        rating: null
        series: "Thursday Next"
        series_id: 13
        series_index: 3
        timestamp: "2009-07-16 22:42:06"
        title: "The Well of Lost Plots"
        */}
        </CardContent>
      </CardActionArea>
    </Card>
  );

}
