import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Title } from 'components';
// For api
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from 'aws-amplify';
// end of for API
//import { format, getTime } from "date-fns";
import { format } from "date-fns";

const useStyles = makeStyles({
  countContext: {
    flex: 1,
    marginTop: 20,
    textAlign: 'center'
  },
});

export default function BookCount() {
  const classes = useStyles();
  const actionBase = '/books/count';
  const [bookData, setBookData] = useState([]);
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      setIsError(false);
    try {
        // Call the AWS API
        const bookData = await API.get('lkhBooksApi', action );
        console.log('data from Lambda REST API: ', bookData)
        setBookData(bookData);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      } catch (err) {
        console.log('error fetching data..', err);
        setIsError(true);
      }
    };

    fetchData();
  }, [action]); // Will ensure that this is only called if the requested action changes


  return (
    <React.Fragment>
      <Title>Number of Books</Title>
      {isError && <div>Something went wrong ...</div>}
       {isLoading && !isError &&
        <Typography component={'div'} variant="h4" align="center">
            <CircularProgress color="primary" />
        </Typography>}
      {!isLoading && (bookData) &&
        <div>
          <Typography component={'div'} variant="h3" className={classes.countContext}>
            {bookData[0].num_books.toLocaleString()}
          </Typography>
          <Typography variant="h5" color="textSecondary" className={classes.countContext}>
            as of
          </Typography>
          <Typography variant="h4" color="textSecondary" className={classes.countContext}>
            {format(new Date(bookData[0].timestamp), "dd MMMM, yyyy")}
          </Typography>
        </div>
      }
    </React.Fragment>
  );

}
