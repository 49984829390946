import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Email = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [values, setValues] = useState({kindleEmail: ''});

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="Manage email settings"
          title="Email settings"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Kindle email address"
            name="kindle-email"
            onChange={handleChange}
            value={values.password}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
          >
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Email.propTypes = {
  className: PropTypes.string
};

export default Email;
