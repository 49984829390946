import { API } from 'aws-amplify';
import { isDefined } from 'helpers';

export default function getApiData(component,api,url) {
  return new Promise(function(resolve, reject) {

    if (!isDefined(url)) reject(component +": no API defined");

    API.get(api, url ).then(function(data) {
      console.log(component," api returned: ",data, " for ",url);
      resolve(data);
    })
    .catch(error => {
       console.error("API error!: "+error);
       reject(component+' failed to execute remote API!', url)
     });
  });
}
