import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {
  SeriesList,
  SeriesBooks
} from './components';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  }
}));

export default function Series() {
  const classes = useStyles();
  const [selectedSeries, setSeries] = useState(null);

  const onSelectionChange = (series) => {
    setSeries(series);
  };

return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <SeriesList handleSelection={onSelectionChange}/>
          </Grid>
          <Grid item xs={7}>
            <SeriesBooks series={selectedSeries}/>
          </Grid>
        </Grid>
      </Container>
  );
}
