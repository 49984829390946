import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
// For api
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getApiData } from 'helpers';
// end of for API

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles({
});

export default function Series(props) {
  const { bookId, series } = props;
//  const classes = useStyles();
  const [title, setTitle] = useState(series + " books");
  const actionBase = '/books/list?book='+bookId;
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [bookData, setBookData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const columns = [
    { name: 'title', label: 'Title',
    options: {
      filter: false,
      sort: true,
      customBodyRenderLite: (dataIndex) => {
          let value = bookData[dataIndex].title;
          let link = "/book/"+bookData[dataIndex].id;
          return <Link color="primary" to={{
                pathname: link,
                state: { bookId: bookData[dataIndex].id }
              }}
            >{value}</Link>
          }
      }
    },
    { name: 'series_index', label: 'Series#', options: {filter: false,sort: true} },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: '',
      }
    },
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    selectableRows: "none",
    selectableRowsHideCheckboxes: true,
    pagination: false,
    resizableColumns: false,
    draggableColumns: {
      enabled: false,
    },
  };


  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      setIsError(false);
      // Call the AWS API to get the book info
      getApiData('Series:1', 'lkhBooksApi', action ).then(function(book) {
        setTitle(book[0].series);
        // Now get the books for the series
        let booksUrl = "/books/list?series="+book[0].series_id;
        return getApiData('Series:2','lkhBooksApi', booksUrl );
      }).then(function(data) {
        setBookData(data);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      })
      .catch(error => {
         setIsError(true);
         console.error("API error!: "+error);
         throw new Error('Failed to get books for Series!')
       });
    };

    fetchData();
  }, [action]); // Will ensure that this is only called if the requested action changes


  return (
    <div>
        {isError && <div>Something went wrong ...</div>}
         {isLoading && !isError &&
          <Typography component={'div'} variant="h4" align="center">
              <CircularProgress color="primary" />
          </Typography>}
        {!isLoading && (bookData) &&
          <MUIDataTable
            title={title}
            data={bookData}
            columns={columns}
            options={tableOptions}
          />
        }
    </div>
  );
/*
<Card className={classes.root}>
  <CardActionArea>
    <CardContent>
    {isError && <div>Something went wrong ...</div>}
     {isLoading && !isError &&
      <Typography component={'div'} variant="h4" align="center">
          <CircularProgress color="primary" />
      </Typography>}
    {!isLoading && (bookData) &&
      <MUIDataTable
        title="Books in Series"
        data={bookData}
        columns={columns}
        options={tableOptions}
      />
    }
    </CardContent>
  </CardActionArea>
</Card>

*/
}
