import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import React from "react";
import { BookCover } from 'components';


const useStyles = makeStyles({
  root: {
    maxWidth: 300,
    height: 450
  },
  media: {
    width: '100%',
    height: '100%',
    '--width': '100%',
    '--height': '100%',
  },
});

export default function Cover(props) {
  const {book} = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <BookCover bookId={book.id} title={book.title}  />
    </Card>
  );

}
