import React, { useState, useEffect } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable from "mui-datatables";
import { API } from 'aws-amplify';

const columns = [
  { name: 'name', label: 'Name', options: {filter: true,sort: true} },
];


export default function SeriesList(props) {
  const { handleSelection } = props;
  const actionBase = '/series/list';
  const [bookData, setBookData] = useState([]);
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  // eslint-disable-next-line
  const [rowSelected, setRowSelected] = useState(null);

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: ' '
      }
    },
    download: false, // hide csv download option
    filter: true,
    print: false,
    pagination: true,
    rowsPerPage: 8,
    resizableColumns: false,
    draggableColumns: {
      enabled: true,
    },
    selectToolbarPlacement: "none",
    selectableRows: "single",
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: true,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      console.log(rowsSelectedData, allRows, rowsSelected);
      setRowSelected(rowsSelected);
      handleSelection((rowsSelected ? bookData[rowsSelected] : null ));
  //    this.setState({ rowsSelected: rowsSelected });
    },
    selectableRowsHeader: false
  };

  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      setIsError(false);
    try {
        // Call the AWS API
        const bookData = await API.get('lkhBooksApi', action );
        console.log('data from Lambda REST API: ', bookData)
        setBookData(bookData);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      } catch (err) {
        console.log('error fetching data..', err);
        setIsError(true);
      }
    };

    fetchData();
  }, [action]); // Will ensure that this is only called if the requested action changes


  return (
    <React.Fragment>
      {isLoading && !isError && (
        <div style={{ position: 'relative', top: '50px', left: '0%' }}>
          <LinearProgress  color="secondary" />
        </div>
      )}
      <MUIDataTable
        title={"Series"}
        data={bookData}
        columns={columns}
        options={tableOptions}
      />
    </React.Fragment>
  );
}
