import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardActions,
  Divider,
  Fab,
  CircularProgress,
  Button
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import { green } from '@material-ui/core/colors';
import { API } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Refresh = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  // API function
  const callAPI = (url) => {
    return new Promise(resolve => {
      // Call the AWS API
      API.get('lkhBooksApi', url )
       .then(function(data) {
         console.log(data);
         resolve(data);
       })
       .catch(function(err) {
         console.log(err);
       })
       ;
    });
  }

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      callAPI('/refresh').then(res => {
        setSuccess(true);
        setLoading(false);
      });
    }
  };



  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          title="Library Refresh"
        />
        <Divider />
        <CardActions >
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <Fab
                aria-label="save"
                color="primary"
                className={buttonClassname}
                onClick={handleButtonClick}
              >
                {success ? <CheckIcon /> : <ThreeSixtyIcon />}
              </Fab>
              {loading && <CircularProgress size={68} className={classes.fabProgress} />}
            </div>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                className={buttonClassname}
                disabled={loading}
                onClick={handleButtonClick}
              >
                Refresh
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </CardActions>
      </form>
    </Card>
  );
};

Refresh.propTypes = {
  className: PropTypes.string
};

export default Refresh;
