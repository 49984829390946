import React, { useState, useEffect } from 'react';
import {useParams, useLocation} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import MuiAlert from '@material-ui/lab/Alert';
import { getApiData,isDefined } from 'helpers';
import {
  Cover,
  Details,
  Summary,
  Formats,
  Series
} from './components';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  }
}));

export default function Book() {
  let { id } = useParams();
  const location = useLocation();
// eslint-disable-next-line
  const [bookId, setBookId] = useState(id);
  const classes = useStyles();
  const [action,setAction] = useState("/books/list?book="+id);
  const [bookData, setBookData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const onBookChange = (bookId) => {
    setIsLoading(true);
    setAction("/books/list?book="+bookId);
    setBookId(bookId);
  };

  useEffect(() => {
     if ( isDefined(action) ) {
       getApiData('Book','lkhBooksApi', action ).then(function(data) {
         setBookData(data);
         setIsLoading(false); // Deliberately only changing the flag when sucessfull
       })
       .catch(error => {
          setIsError(true);
          console.error("Failed to get book!",bookId);
        });
    }

  }, [bookId,action]); // Will ensure that this is only called if the requested action changes

  useEffect(() => {
    let id = -1;
    if (isDefined(location.state)) {
      id = location.state.bookId;
    } else {
      let tokens = location.pathname.split("/");
      id = tokens[2];
    }
    if (bookId!==id) {
      // console.log("location changed",bookId,id)
      onBookChange(id);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
   }, [location]);

  return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container direction="row" spacing={2}>
          <Grid item container direction="column" xs={12} md={3} lg={2}>
            <Grid item >
              {isError && <MuiAlert severity="error">Failed to fetch book details!</MuiAlert>}
              {isLoading && !isError &&
                <Skeleton variant="rect" height={450} />}
              {!isLoading && !isError &&
                <Cover book={bookData[0]}/>}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={12} md={5} lg={5} spacing={2}>
            <Grid item >
              {isError && <MuiAlert severity="error">Failed to fetch book details!</MuiAlert>}
              {isLoading && !isError &&
                <Skeleton variant="rect" height={100} />}
              {!isLoading && !isError &&
                <Details book={bookData[0]}/>}
            </Grid>
            <Grid item >
              {isError && <MuiAlert severity="error">Failed to fetch book details!</MuiAlert>}
              {isLoading && !isError &&
                <Skeleton variant="rect" height={250} />}
              {!isLoading && !isError &&
                <Summary bookId={bookId}/>}
            </Grid>
          </Grid>
          <Grid item container direction="column" xs={12} md={4} lg={4} spacing={2}>
            <Grid item >
              {isError && <MuiAlert severity="error">Failed to fetch book details!</MuiAlert>}
              {isLoading && !isError &&
                <Skeleton variant="rect" height={80} />}
              {!isLoading && !isError &&
                <Formats bookId={bookId}/>}
            </Grid>
            <Grid item >
              {isError && <MuiAlert severity="error">Failed to fetch book details!</MuiAlert>}
              {isLoading && !isError &&
                <Skeleton variant="rect" height={300} />}
              {!isLoading && !isError &&
                <Series bookId={bookId} series={bookData[0].series} />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
  );
}
