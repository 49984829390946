import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import LinearProgress from '@material-ui/core/LinearProgress';
import { getApiData, isDefined } from 'helpers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { BookCover, Title } from 'components';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function RecentBooks() {
  const classes = useStyles();
  const actionBase = '/books/recent';
  const title = <Title>Recent Books</Title>;
  const [bookData, setBookData] = useState([]);
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const columns = [
    { name: 'title', label: 'Title', options: {filter: true,sort: true,
      customBodyRenderLite: (dataIndex) => {
        let title = bookData[dataIndex].title;
        let bookId = bookData[dataIndex].id;
        let link = "/book/"+bookId;
          return <Link color="primary" to={{
                pathname: link,
                state: { bookId: bookId }
              }}
            ><Grid container wrap="nowrap" direction="row" justify="flex-start" alignItems="center" spacing={1}>
                <Grid item width="10%">
                  <BookCover bookId={bookId} title={title} size={{height:60,maxWidth:45}} />
                </Grid>
                <Grid item>{title}</Grid>
              </Grid>
            </Link>
          }
    } },
    { name: 'author_sort', label: 'Author', options: {filter: true,sort: true} },
    { name: 'series', label: 'Series', options: {filter: true,sort: true} },
    { name: 'series_index', label: 'Series#', options: {filter: false,sort: true} },
    { name: 'last_modified', label: 'Last Modified',
        options: {filter: false,sort: true,
          setCellProps: () => ({style: {whiteSpace:'nowrap'}}),
          customBodyRender: (value, tableMeta, updateValue) => {return value.substring(0, 16);} }
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: '',
      }
    },
    download: false, // hide csv download option
    filter: false,
    toolbar: false,
    selectableRows: "none",
    selectableRowsHideCheckboxes: true,
    print: false,
    search: false,
    viewColumns: false,
    pagination: false,
    elevation: 0,
    rowsPerPage: 10,
    resizableColumns: false,
    draggableColumns: {
      enabled: false,
    }
  };


  useEffect(() => {
    if ( isDefined(action) ) {
      setIsLoading(true);
      setIsError(false);

      getApiData('RecentBooks','lkhBooksApi', action ).then(function(data) {
        setBookData(data);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      })
      .catch(error => {
         setIsError(true);
         console.error("Failed to get recent books!");
       });
    } else {
      setBookData([]);
      setIsLoading(false);
    }

  }, [action]); // Will ensure that this is only called if the requested action changes


  return (
    <React.Fragment>
      {isLoading && !isError && (
        <div style={{ position: 'relative', top: '50px', left: '0%' }}>
          <LinearProgress  color="secondary" />
        </div>
      )}
      <MuiThemeProvider theme={createMuiTheme({
          overrides: {
            MuiTableCell: {
              root: {
                padding: '0px 8px 0px 8px',
              },
            },
          },
        })}>
        <MUIDataTable
          title={title}
          data={bookData}
          columns={columns}
          options={tableOptions}
        />
        </MuiThemeProvider>
      <div className={classes.seeMore}>
        <Link color="primary" to="/books" >
          See more books...
        </Link>
      </div>
    </React.Fragment>
  );
}
