/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* xxxxxxeslint-disable react/no-unused-vars */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemIcon, ListSubheader, Divider } from '@material-ui/core';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  // eslint-disable-next-line
  const { pages, className, ...rest } = props;

  return (
    <List>
      {pages.map((page,index) => (
        <div key={page.title+"item"}>
          {page.needsDivider && <Divider key={page.title+"-div"} />}
          {page.isHeader
            ? <ListSubheader key={page.title} inset>{page.title}</ListSubheader>
            : <ListItem button key={page.title} component={CustomRouterLink} to={page.href} >
                  {page.icon}
                  <ListItemIcon key={page.title+"-icon"} >
                </ListItemIcon>
                <ListItemText key={page.title+"-text"} primary={page.title} />
              </ListItem>
          }
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
