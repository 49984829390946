import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  BookHistory,
  BookCount,
  RecentBooks
} from './components';



const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={2}>
          {/* Chart */}
          <Grid item xs={12} md={9} lg={10}>
            <Paper className={fixedHeightPaper}>
              <BookHistory />
            </Paper>
          </Grid>
          {/* Book Count */}
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={fixedHeightPaper}>
              <BookCount />
            </Paper>
          </Grid>
          {/* Recent Books */}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <RecentBooks />
            </Paper>
          </Grid>
        </Grid>
      </Container>
  );
}
