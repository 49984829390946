import { makeStyles } from '@material-ui/core/styles';
// For api
import React, { useEffect, useState } from "react";
import { Amplify } from 'aws-amplify';
import { getApiData, isDefined } from 'helpers';
// end of for API
import { AmplifyS3Image } from '@aws-amplify/ui-react';
import awsconfig from 'aws-exports';

Amplify.configure(awsconfig);


export default function BookCover(props) {
  const {bookId, title, size} = props;
  const coverMaxWidth = (isDefined(size) ? (isDefined(size.maxWidth) ? size.maxWidth : size.height/1.5) : '100%');
  const coverWidth = (isDefined(size) ? (isDefined(size.width) ? size.width : coverMaxWidth) : '100%');
  const coverHeight = (isDefined(size) ? size.height : '100%');

  const useStyles = makeStyles({
    root: {
      maxWidth: coverMaxWidth,
      width: coverWidth,
      height: coverHeight,
      '--width': '100%',
      '--height': '100%',
      align: 'left'
    },
  });
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [imageKey, setImageKey] = useState(bookId+".jpg");
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    setImageKey(bookId+".jpg");
    setIsDefault(false);
  }, [bookId] );

  function S3Cover({imgKey, handleOnError}) {

    useEffect(() => {
//      console.log("AWS Cover", imgKey);
    } );

    return (
      <AmplifyS3Image imgKey={imgKey} handleOnError={handleOnError}  />
    );
  }

  const onImageLoadError = () => {
    // Could not locate the actual cover, so trigger the use of default cover
    setIsDefault(true);

    // Now get the cover from Box
    let action = '/cover?id='+bookId;
    getApiData('Cover','lkhBooksApi', action ).then(function(data) {
      setIsDefault(false);
    })
    .catch(error => {
       setIsDefault(true);
       console.error("Failed to get book cover!",bookId);
     });

  }

  return (
    <div className={classes.root}>
          {!isDefault &&
            <S3Cover imgKey={imageKey} handleOnError={onImageLoadError}  />
          }
          {isDefault &&
            <img alt={title} src="/images/default_cover.png" className={classes.root}/>
          }
    </div>
  );

}
