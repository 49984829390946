import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Authors as AuthorsView,
  Book as BookView,
  Books as BooksView,
  Series as SeriesView,
  Settings as SettingsView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" title="Dashboard"/>
      <RouteWithLayout component={BooksView} layout={MainLayout} path="/books"  title="Books"/>
      <RouteWithLayout exact component={BookView} layout={MainLayout} path="/book/:id"  title="Book"/>
      <RouteWithLayout component={AuthorsView} layout={MainLayout} path="/authors"   title="Authors"/>
      <RouteWithLayout component={SeriesView} layout={MainLayout} path="/series"   title="Series"/>
      <RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/settings"    title="Settings"/>
      <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found"   title="Page not Found"/>
      <Redirect exact from="/" to="/not-found" />
    </Switch>
  );

};

export default Routes;
