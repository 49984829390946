/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:86550da5-fc9e-40a8-a83f-847e02bac173",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_hlfPj50k0",
    "aws_user_pools_web_client_id": "6s7ghf1k0dskgvbirnd34n4o8d",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "lkhBooksApi",
            "endpoint": "https://9a79y91bjd.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "images-books-limekilnhall-net190912-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
