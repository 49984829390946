import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useDebounce } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    overflow: 'auto',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
    marginTop: 8
  },
}));

export default function SearchBox(props) {
  const { label, handleChange } = props;
  const classes = useStyles();

  // Debounce callback
  const [debouncedValue, value, setValue] = useDebounce('', 250);

  useEffect(() => {
    // Pass the debounced Value up
    handleChange(debouncedValue);
  }, [debouncedValue,handleChange]);

  const onLocalChange = e => {
    setValue(e.target.value);
  };

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        onChange={onLocalChange}
        value={value}
        placeholder={label}
        inputProps={{ 'aria-label': {label} }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton type="submit" className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

SearchBox.propTypes = {
  children: PropTypes.node,
};
