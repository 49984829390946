import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Title } from 'components';
import DOMPurify from 'dompurify';
// For api
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getApiData,isDefined } from 'helpers';
// end of for API
//import { format, getTime } from "date-fns";

const useStyles = makeStyles({
});

export default function Summary(props) {
  const {bookId} = props;
  const classes = useStyles();
  const actionBase = '/books/book/desc?id='+bookId;
  const [bookData, setBookData] = useState([]);
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    if ( isDefined(action) ) {
      // setIsLoading(true);
      setIsError(false);

      getApiData('Summary','lkhBooksApi', action ).then(function(data) {
        setBookData(data);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      })
      .catch(error => {
         setIsError(true);
         console.error("Failed to get book summary!",bookId);
       });
   }

  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [action]); // Will ensure that this is only called if the requested action changes

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
        <Title>Summary</Title>
        {isError && <div>Something went wrong ...</div>}
         {isLoading && !isError &&
          <Typography component={'div'} variant="h4" align="center">
              <CircularProgress color="primary" />
          </Typography>}
        {!isLoading && (bookData) && (bookData.length > 0) &&
          <div>
            <Typography component={'div'} variant="body1" >
              <div  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bookData[0].text) }}/>
            </Typography>
          </div>
        }
        </CardContent>
      </CardActionArea>
    </Card>
  );

}
