//import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip } from 'recharts';
import {PureComponent} from "react";
import { Title } from 'components';
import { format } from "date-fns";
// For api
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from 'aws-amplify';
// end of for API

class CustomizedAxisTick extends PureComponent {
  render() {
    // eslint-disable-next-line
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{yearFormatter(payload.value)}</text>
      </g>
    );
  }
}
const dateFormatter = month => {
  return format(new Date(month), "MMM yyyy");
};
const yearFormatter = month => {
  return format(new Date(month), "yyyy");
};

export default function BookHistory() {
  const theme = useTheme();
  const actionBase = '/books/history';
  const [bookData, setBookData] = useState([]);
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      // setIsLoading(true);
      setIsError(false);
    try {
        // Call the AWS API
        const bookData = await API.get('lkhBooksApi', action );
        console.log('data from Lambda REST API: ', bookData)
        setBookData(bookData);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      } catch (err) {
        console.log('error fetching data..', err);
        setIsError(true);
      }
    };

    fetchData();
  }, [action]); // Will ensure that this is only called if the requested action changes

  return (
    <React.Fragment>
      <Title>Books added over time</Title>
      {isError && <div>Something went wrong ...</div>}
       {isLoading && !isError &&
        <div align="center">
            <CircularProgress color="primary" />
        </div>}
      {!isLoading && (bookData) &&
        <ResponsiveContainer>
          <LineChart
            data={bookData}
            margin={{
              top: 16,
              right: 16,
              bottom: 16,
              left: 24,
            }}
          >
            <XAxis dataKey="MONTH" stroke={theme.palette.text.secondary}
            domain={[bookData[0].MONTH, bookData[bookData.length-1].MONTH]}
            interval="preserveEnd"
            tickFormatter = {yearFormatter}
            tick={<CustomizedAxisTick />}
            type = 'number' scale="time" />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                Books (#)
              </Label>
            </YAxis>
            <Tooltip labelFormatter={dateFormatter} formatter={(value, name) => ([value,'#Books: '])}/>
            <Line type="monotone" dataKey="NUM_BOOKS" stroke={theme.palette.primary.main} activeDot={true} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      }
    </React.Fragment>
  );

}
