import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
//import validate from 'validate.js';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
//import validators from './common/validators';
import Routes from './Routes';

import { withAuthenticator } from '@aws-amplify/ui-react';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter forceRefresh={false} >
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default withAuthenticator( App );
