import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import BookRoundedIcon from '@material-ui/icons/BookRounded';
import { Title } from 'components';
import { stringToColour } from 'helpers';
// For api
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getApiData,isDefined } from 'helpers';
// end of for API

const useStyles = makeStyles({
  chip: {
    margin: 2,
  },
});

export default function Formats(props) {
  const {bookId} = props;
  const classes = useStyles();
  const actionBase = '/books/book/formats?id='+bookId;
  const [bookData, setBookData] = useState([]);
  // eslint-disable-next-line
  const [action, setAction] = useState(actionBase);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);


  useEffect(() => {
    if ( isDefined(action) ) {
      // setIsLoading(true);
      setIsError(false);

      getApiData('Formats','lkhBooksApi', action ).then(function(data) {
        setBookData(data);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      })
      .catch(error => {
         setIsError(true);
         console.error("Failed to get book summary!",bookId);
       });
   }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]); // Will ensure that this is only called if the requested action changes


  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
        {isError && <div>Something went wrong ...</div>}
         {isLoading && !isError &&
           <div>
            <Title>Formats</Title>
            <Typography component={'div'} variant="h4" align="center">
                <CircularProgress color="primary" />
            </Typography>
          </div>}
          {(bookData||[]).map((row) => (
               <Chip key={row.format}
                 icon={<BookRoundedIcon/>}
                 label={row.format}
                 className={classes.chip}
                 style={{backgroundColor: stringToColour(row.format)}}
                 color='secondary'
               />
             ))}
        </CardContent>
      </CardActionArea>
    </Card>
  );

}
