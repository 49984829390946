import React, { useState, useEffect } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { getApiData, isDefined } from 'helpers';
import { BookCover, Title } from 'components';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


function useAction(author) {
  // eslint-disable-next-line
  const [action, setAction] = useState( null );

  useEffect(() => {
    if (isDefined(author)){
      setAction( "/books/list?author="+author.id );
    } else {
      setAction( null );
    }
  }, [author,action]);

  return action;
}

export default function AuthorBooks(props) {
  const { author } = props;
  const title = <Title>{isDefined(author) ? author.name + "'s books" : "Books"}</Title>;
  const [bookData, setBookData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const action = useAction(author);

  const columns = [
    { name: 'title', label: 'Title', options: {filter: true,sort: true,
      customBodyRenderLite: (dataIndex) => {
        let title = bookData[dataIndex].title;
        let bookId = bookData[dataIndex].id;
        let link = "/book/"+bookId;
          return <Link color="primary" to={{
                pathname: link,
                state: { bookId: bookId }
              }}
            ><Grid container wrap="nowrap" direction="row" justify="flex-start" alignItems="center" spacing={1}>
                <Grid item width="10%"><BookCover bookId={bookId} title={title}
                size={{height:60,maxWidth:45}} /></Grid>
                <Grid item>{title}</Grid>
              </Grid>
            </Link>
          }
    } },
    { name: 'series', label: 'Series', options: {filter: true,sort: true} },
    { name: 'series_index', label: 'Series#', options: {filter: false,sort: true} },
    { name: 'rating', label: 'Rating', options: {filter: false,sort: true} },
    { name: 'last_modified', label: 'Last Modified',
        options: {filter: false,sort: true,
          setCellProps: () => ({style: {whiteSpace:'nowrap'}}),
          customBodyRender: (value, tableMeta, updateValue) => {return value.substring(0, 16);} }
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: '',
      }
    },
    filter: true,
    search: true,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    selectableRows: "none",
    selectableRowsHideCheckboxes: true,
    pagination: false,
    resizableColumns: false,
    draggableColumns: {
      enabled: false,
    },
  };
  // sortOrder: {name: 'series_index',direction: 'asc'}

  useEffect(() => {
    if ( isDefined(action) ) {
      setBookData([]);
      setIsLoading(true);
      setIsError(false);

      getApiData('AuthorBooks','lkhBooksApi', action ).then(function(data) {
        setBookData(data);
        setIsLoading(false); // Deliberately only changing the flag when sucessfull
      })
      .catch(error => {
         setIsError(true);
         console.error("Failed to get books for author!",author);
       });
    } else {
      setBookData([]);
      setIsLoading(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]); // Will ensure that this is only called if the requested action changes

  return (
    <React.Fragment>
      {isLoading && !isError && (
        <div style={{ position: 'relative', top: '50px', left: '0%' }}>
          <LinearProgress  color="secondary" />
        </div>
      )}
      <MuiThemeProvider theme={createMuiTheme({
          overrides: {
            MuiTableCell: {
              root: {
                padding: '0px 8px 0px 8px',
              },
            },
          },
        })}>
        <MUIDataTable
          title={title}
          data={bookData}
          columns={columns}
          options={tableOptions}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}
